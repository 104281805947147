<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-container class="pa-0">
    <v-row justify="center">
      <h2 id="scrollTarget" class="mt-6 mb-12">{{ activeTranslationWrapper?.title }}</h2>
    </v-row>
    <v-row class="d-flex justify-space-around mx-auto px-0 maxwidth-container">
      <v-col
        v-for="(card, index) in benefitCardPayload?.benefit_card_items"
        :key="index"
        class="mr-0 mr-sm-16 mr-md-0"
        cols="11"
        offset="0"
        offset-sm="1"
        offset-md="0"
        sm="6"
        md="4"
        xl="4">
        <CdeCard class="fill-height d-flex flex flex-column pa-7 px-md-5" width="100%">
          <v-card-item class="pb-0 benefit-icon d-flex align-center justify-center">
            <v-icon class="pt-1 my-7 align-self-center" size="100px">
              custom:{{ card.benefit_card_id.icon }}
            </v-icon>
          </v-card-item>
          <v-card-item class="pb-0">
            <v-card-title class="card-header font-weight-bold">
              {{ activeTranslationCard(card.benefit_card_id)?.title }}
            </v-card-title>
          </v-card-item>
          <v-card-item class="pb-0">
            <v-card-text
              class="pb-0 px-0"
              v-html="sanitizeHtml(activeTranslationCard(card.benefit_card_id)?.description)" />
          </v-card-item>
        </CdeCard>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col cols="10" sm="8" md="5" lg="4" xl="3" xxl="2" class="text-center">
        <ButtonPrimary
          color="primary"
          class="mt-4"
          width="100%"
          height="80px"
          :href="sanitizeHtml(benefitCardPayload.button_destination)"
          :target="benefitCardPayload.button_destination?.includes('http') ? '_blank' : '_self'">
          {{ activeTranslationWrapper?.button_label }}
        </ButtonPrimary>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts" setup>
import type { LocaleObject } from '@nuxtjs/i18n'
import type { BenefitCard, BenefitCardTranslation, Cards, CardsTranslation } from '~/types/types'
import { sanitizeHtml } from '~/util/sanitizer'

const props = defineProps<{
  scPayload: Cards
}>()

const { locale, locales } = useI18n()
const benefitCardPayload = props.scPayload

const currentLocaleIso = computed(() => {
  return locales.value?.find((l: LocaleObject) => l.code === locale.value)?.language as string
})

const activeTranslationWrapper = computed(() => {
  return benefitCardPayload.translations?.find(
    (t: CardsTranslation) => t.languages_code === currentLocaleIso.value,
  )
})
function activeTranslationCard(card: BenefitCard) {
  return card.translations?.find(
    (t: BenefitCardTranslation) => t.languages_code === currentLocaleIso.value,
  )
}
</script>

<style scoped lang="scss">
.card-header {
  white-space: wrap;
  line-height: normal;
}
.maxwidth-container {
  max-width: 1350px !important;
}
.benefit-icon {
  min-height: 180px;
}
</style>
